import React, { Component } from 'react';
import i18n from 'i18next';
import ReactLoading from 'react-loading';
import phpicon from './php-icon.png';
import jsicon from './js.png';
import htmlicon from './html.png';
import cssicon from './css.png';
import { MdOutlineClose, MdOpenInNew, MdDriveFileRenameOutline, MdDeleteForever } from "react-icons/md";

export class Page extends Component {

    constructor(props){
        super(props);
    
        this.state= { info: props.info, body: '', oldBody: '', showDeletePage: false, isLoading: false };
    }

    componentDidMount () {
        this.getPageInfo();     
     }
 
     getPageInfo = () => {
        
        this.setState({ isLoading: true });

         var sessiontoken = localStorage.getItem('session_token');
 
         const requestOptions = {
             method: 'POST',
             headers: new Headers({
               'Content-Type': 'application/x-www-form-urlencoded',
             }),
             body: JSON.stringify({
               token: sessiontoken,
               id: this.state.info.id
             })
             };
           
                 const apiUrl = 'https://easy-code.cloud/api/v1/page/info';
       
           fetch(apiUrl, requestOptions)
           .then(r => r.json())
           .then(r => {
                         this.setState({ info: r.page, body: r.page.body, oldBody: r.page.body, isLoading: false });
              
           }).catch(e => {
          
               localStorage.removeItem('session_token');
               window.location.reload();
           });
     }
  

     changeBody = (e) => {
        var insert = String(e.target.value);      
        this.setState({ body: insert });  
     }

     closePage = () => {
        this.props.closePage();
     }

     openBrowser = () => {
        window.open(this.state.info.url, '_blank');
     }

     renamePage = () => {

     }

     deletePage = () => {
        this.setState({ showDeletePage: true });
     }

     dismiss = () => {
        this.setState({ showDeletePage: false });
    }

    confirmDelete = () => {
        
        if(this.state.isLoading){
            return;
        }
        this.setState({ isLoading: true });

        var sessiontoken = localStorage.getItem('session_token');
 
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              id: this.state.info.id
            })
            };
          
                const apiUrl = 'https://easy-code.cloud/api/v1/page/delete';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            
            window.location.reload();
          }).catch(e => {
         
              localStorage.removeItem('session_token');
              window.location.reload();
          });
    }

     savePage = () => {
        if(this.state.body == this.state.oldBody){
            return;
        }
        if(this.state.isLoading){
            return;
        }
        this.setState({ isLoading: true });

        var sessiontoken = localStorage.getItem('session_token');
 
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              id: this.state.info.id,
              body: this.state.body
            })
            };
          
                const apiUrl = 'https://easy-code.cloud/api/v1/page/save';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            this.setState({ info: r.page, body: r.page.body, oldBody: r.page.body, isLoading: false });
             
          }).catch(e => {
         
              localStorage.removeItem('session_token');
              window.location.reload();
          });
     }

    render() {
        return (
            <div>
           
{this.state.isLoading &&
        <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(10,10,10,0.0)',
    width: '70%',
    height: '85%'
      }}>
        <ReactLoading type="spin" color="white" height="30px" width="30px" />
        </div>
      }
            <div style={{ display: 'flex', marginRight: '10px', padding: '5px' }}>
          
<div style={{ marginTop: '5px', marginLeft: '5px', width: '100%',  display: 'flex'}}>
{this.state.info.extension == 'html' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={htmlicon} />}
                {this.state.info.extension == 'css' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={cssicon} />}
                {this.state.info.extension == 'js' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={jsicon} />}
<div style={{ marginLeft: '10px', marginRight: '5px', color: 'rgb(240,240,240)', fontSize: '12px', fontWeight: 'normal', textAlign: 'left'}}>{this.state.info.name}.{this.state.info.extension}</div>
<div>
           <MdOutlineClose onClick={this.closePage} style={{ marginLeft: '5px', cursor: 'pointer' }} color="white" size="15px"  />
           </div>
</div>

            <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'right', marginRight: '0px', padding: '5px' }}>
            <div>
           <MdOpenInNew onClick={this.openBrowser} style={{ marginLeft: '10px', cursor: 'pointer' }} color="white" size="15px"  />
           </div>
          
           <div>
           <MdDeleteForever onClick={this.deletePage} style={{ marginLeft: '15px', cursor: 'pointer' }} color="white" size="15px"  />
           </div>
          
           </div>
           </div>
            <div style={{ height: '0.5px', backgroundColor: 'rgba(150,150,150,1.0)' }}></div>
                <textarea 
                className="lined" 
                id="lined"
                value={this.state.body} 
                onChange={this.changeBody}
                />
       
            {this.state.body != this.state.oldBody &&
            <div style={{ marginTop: '-5px', display: 'flex', justifyContent: 'right', alignItems: 'right', marginRight: '10px' }}>
                      <p style={{  marginRight: '5px', marginBottom: '5px', cursor: 'pointer',  fontWeight: 'bold', height: '25px', width: '120px', borderRadius: '5px', backgroundColor: 'rgba(255,255,255,1.0)', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#282c34'}}
            onClick={this.savePage}
            >{i18n.t("save")}</p>
      
                     </div>
            }
            

            {this.state.showDeletePage && 
                     <div className="popup-box">
                     <div className="box">
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <div style={{ color: 'rgb(30,30,30)', marginTop: '5px', fontSize: '16px', fontWeight: 'bold', width: '100%', textAlign: 'center'}}>  {i18n.t("want_delete_page")}</div> 
                     </div>
                    <br />
     

                     <div  style={{ marginTop: '10px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '50%', borderRadius: '5px', backgroundColor: 'red', fontSize: '14px', 
                     textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
                     onClick={this.confirmDelete}
                     >{i18n.t("delete")}</p>
                     </div>
                     
         
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: this.state.main_color}}
             onClick={this.dismiss}
             >{i18n.t("cancel")}</p>
             </div>
         
                     </div>
                 </div>
                 }


            </div>
        )
    }
}

export default Page

import React, { Component } from 'react';
import i18n from 'i18next';
import ReactLoading from 'react-loading';
import { MdNoteAdd } from "react-icons/md";
import phpicon from './php.png';
import jsicon from './js.png';
import htmlicon from './html.png';
import cssicon from './css.png';

export class PagesList extends Component {

    constructor(props){
        super(props);
        this.state= { isLoading: false, showAddPage: false, pagename: '', showError: '', pages: [], currentPage: null, type: 'html' };
    }

  
    componentDidMount () {
       this.getPages();     
    }

    changeType = (e) => {
        var type = e.target.value;
      
        this.setState({ type: type, showError: '' });
        
      }

    getPages = () => {
        var sessiontoken = localStorage.getItem('session_token');

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken
            })
            };
          
                const apiUrl = 'https://easy-code.cloud/api/v1/page/list';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            this.setState({pages: r.pages });
             
          }).catch(e => {
              localStorage.removeItem('session_token');
              window.location.reload();
          });
    }

    addPage = () => {
        this.setState({ showAddPage: true, pagename: '' });
    }

    dismiss = () => {
        if(this.state.isLoading){
            return;
        }
        this.setState({ showAddPage: false, pagename: '' });
    }

    insertName = (e) => {
        var insert = String(e.target.value);      
        this.setState({ pagename: insert, showError: ''});     
    }

     onlyLettersAndNumbers(str) {
        return /^[A-Za-z0-9-_]*$/.test(str);
      }

    selectPage = (page) => {
        this.setState({ currentPage: page.id });
        this.props.selectPage(page);
    }

    createPage = () => {
        if(this.state.isLoading){
            return;
        }
    
        var pagename = this.state.pagename;

        if(pagename.length<1 || !this.onlyLettersAndNumbers(pagename)){
            this.setState({ showError: i18n.t("not_valid_data")});  
            return;
        }

        for(var i=0; i<this.state.pages.length; i++){
            if(this.state.pages[i]['name'] == pagename && this.state.pages[i]['extension'] == this.state.type ){
                this.setState({ showError: i18n.t("page_exists")});  
            return;
            }
        }

        
        this.setState({ isLoading: true });

        var sessiontoken = localStorage.getItem('session_token');

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              name: pagename,
              type: this.state.type
            })
            };
          
                const apiUrl = 'https://easy-code.cloud/api/v1/page/new';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
            this.setState({pages: r.pages, showAddPage: false, isLoading: false });
             
          }).catch(e => {
              localStorage.removeItem('session_token');
              window.location.reload();
          });
    }

    render() {
        return (
            <div>
{this.state.isLoading &&
        <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(10,10,10,0.0)',
    width: '20%',
    height: '50%'
      }}>
        <ReactLoading type="spin" color="white" height="30px" width="30px" />
        </div>
      }
            <div style={{ padding: '10px' }}>
           <MdNoteAdd onClick={this.addPage} style={{ cursor: 'pointer' }} color="white" size="15px"  />
            </div>
            <div style={{ height: '0.5px', backgroundColor: 'rgba(150,150,150,1.0)' }}></div>
            
            { this.state.pages.map((element, k) =>
            <div key={k}>
            {this.state.currentPage == element.id &&             
                <div onClick={() => this.selectPage(element)} style={{ cursor: 'pointer', marginTop: '8px', color: 'white' }} 
                >
                <div style={{  backgroundColor: 'rgba(255,255,255,0.2)', minHeight: '20px', marginLeft: '0px', width: '100%', textAlign: 'center', justifyContent: 'center', 
                alignItems: 'center', display: 'flex'}}>
                 {element.extension == 'html' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={htmlicon} />}
                {element.extension == 'css' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={cssicon} />}
                {element.extension == 'js' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={jsicon} />}
                {element.extension == 'php' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={phpicon} />}
                
                <div style={{ marginLeft: '10px', marginRight: '5px', color: 'rgb(240,240,240)', fontSize: '12px', 
                fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{element.name}.{element.extension}</div>
                </div>
                </div>
                }
                 {this.state.currentPage != element.id &&             
                <div onClick={() => this.selectPage(element)} style={{ cursor: 'pointer', marginTop: '8px', color: 'white' }} 
                 >
                <div style={{  marginLeft: '0px', width: '100%', minHeight: '20px', textAlign: 'center', justifyContent: 'center', 
                alignItems: 'center', display: 'flex'}}>
                {element.extension == 'html' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={htmlicon} />}
                {element.extension == 'css' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={cssicon} />}
                {element.extension == 'js' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={jsicon} />}
                {element.extension == 'php' && 
                <img style={{ marginLeft: '10px', width: '15px', maxHeight: '15px' }} src={phpicon} />}

                <div style={{ marginLeft: '10px', marginRight: '5px', color: 'rgb(240,240,240)', fontSize: '12px', 
                fontWeight: 'normal', width: '100%', textAlign: 'left'}}>{element.name}.{element.extension}</div>
                </div>
                </div>
                }
               </div>

            ) }
            

            {this.state.showAddPage && 
                     <div className="popup-box">
                     <div className="box">
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <div style={{ color: 'rgb(30,30,30)', marginTop: '5px', fontSize: '16px', fontWeight: 'bold', width: '100%', textAlign: 'center'}}>  {i18n.t("create_page")}</div> 
                     </div>
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                    <div style={{ color: 'rgb(100,100,100)', marginTop: '15px', fontSize: '14px', fontWeight: 'normal', width: '60%', textAlign: 'center'}}>{i18n.t("create_page_details")}</div> 
                    </div>

                    <div  style={{marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'white'}}>
                <div>
                <input 
                style={{ paddingLeft: '15px', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '240px', borderRadius: '8px', 
                backgroundColor: 'white', outlineWidth: '0', borderColor: 'black', borderWidth: '1px', fontSize: '12px'}} type="text"  
                name="name"
                autoComplete="off" 
                value={this.state.pagename}
                placeholder={i18n.t("page_name")}
                onChange={this.insertName}
                />
                </div>
                </div>
                </div>

                <div style={{  marginTop: '20px', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px'}} >
                <select onChange={this.changeType} value={this.state.type}>

                              
                <option value="html">HTML</option>                   
                <option value="css">CSS</option>                     
                <option value="js">JAVASCRIPT</option>                 
                <option value="php">PHP</option>    

                </select>
                </div>

                {this.state.showError != '' &&
                <div  style={{marginTop: '30px', color: 'red', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {this.state.showError}
                </div>
                }

                     <div  style={{ marginTop: '10px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                     <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '45px', width: '50%', borderRadius: '5px', backgroundColor: '#282c34', fontSize: '14px', 
                     textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
                     onClick={this.createPage}
                     >{i18n.t("create")}</p>
                     </div>
                     
         
                     <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: this.state.main_color}}
             onClick={this.dismiss}
             >{i18n.t("cancel")}</p>
             </div>
         
                     </div>
                 </div>
                 }
            </div>
        )
    }
}

export default PagesList

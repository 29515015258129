import logo from './ep_logo_w.png';
import './App.css';
import { Component } from 'react';
import Login from './Login';
import Home from './Home';
import { MdLogout } from "react-icons/md";

class App extends Component {
  
  constructor(props){
    super(props);

    this.state = {isReady: false, isLogged: false };
        
        
  }

  componentDidMount () {
      var sessiontoken = localStorage.getItem('session_token');
      if(sessiontoken === null){
         this.setToken();
      }else{
        this.checkToken(sessiontoken);
      }
  }


  checkToken = (session_token) => {
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify({
        token: session_token
      })
      };
    
          const apiUrl = 'https://easy-code.cloud/api/v1/hello/check';

    fetch(apiUrl, requestOptions)
    .then(r => r.json())
    .then(r => {
      
      if(r.logged === true){
        this.setState({ isLogged: true, isReady: true});
      }else{
        this.setState({ isLogged: false, isReady: true});
      }    
     
      
    }).catch(e => {
        localStorage.removeItem('session_token');
        window.location.reload();
    });

  }

  setToken = () => {
  
      const requestOptions = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
       
        };
      
            const apiUrl = 'https://easy-code.cloud/api/v1/hello/client';
  
      fetch(apiUrl, requestOptions)
      .then(r => r.json())
      .then(r => {
        
        localStorage.setItem('session_token', r.token);
        this.setState({ isLogged: false, isReady: true});
       
        
      }).catch(e => {
          localStorage.removeItem('session_token');
         window.location.reload();
      });
  
    }

    logout = () => {
      localStorage.removeItem('session_token');
      window.location.reload();
    }


  render(){
    
    return (
     
    <div style={{ backgroundColor: '#282c34'}}>
    
     <div style={{ display: 'flex', marginRight: '10px', padding: '5px' }}>
          
<div style={{  width: '100%',  display: 'flex'}}>
<img style={{ marginTop: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'block', width: '200px' }} src={logo} />

</div>
{this.state.isLogged &&
            <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'right', marginRight: '15px', marginTop: '25px', padding: '5px' }}>
           <div>
           <MdLogout onClick={this.logout} style={{ cursor: 'pointer' }} color="white" size="15px"  />
           </div>  
           </div>
  }
           </div>

          { (this.state.isReady && !this.state.isLogged) &&
          <div className="App"><Login /></div> }

{ (this.state.isReady && this.state.isLogged) &&
          <div className="App"><Home /></div> }


    </div>
  );
    }
}

export default App;

import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
  en: {
    translation: {
      delete_account: "Delete account",
      logout: "Logout",
      need_account: "Welcome to the Easy Code development environment!",
      need_account_2: "Log in or create an account to start writing and testing your code.",
      register_now: "Register now",
      login: "Login",
      or: "or",
      want_delete: "Do you want to delete this account?",
      want_delete_text: "All the created pages will be deleted and it will no longer be possible to recover them.",
      password_confirm: "Enter your password to confirm",
      delete: "Delete",
      cancel: "cancel",
      save: "Save",
      open_browser: "Open in browser",
      add_clipboard: "Add to clipboard",
      not_valid_data: "Not valid data",
      not_agreed: "Please agree on Privacy Policy & Terms and Conditions",
      terms_agree: "I agree Privacy Policy & Terms and Conditions",
      create_page: "Create page",
      create_page_details: "Enter only letters, numbers, the '-' or '_' symbol. Do not enter spaces, periods or special characters.",
      page_name: "Page name",
      create: "Create",
      page_exists: "The page already exists",
      want_delete_page: "Do you want to delete this page?",
      phone_number: "Mobile number",
      enter_phone: "Enter your mobile number",
      verification_code: "Code",
      confirm: "Confirm",
      confirm_code: "Enter the activation code sent by sms",
      mobile_exists: "The mobile number you entered is already registered",
      contact_us: "Contact us!",
      need_help: "Do you need help?"
    },
  },
  it: {
    translation: {
      delete_account: "Elimina account",
      logout: "Logout",
      need_account: "Benvenuto nell'ambiente di sviluppo di Easy Code!",
      need_account_2: "Accedi o crea un account per iniziare a scrivere e testare il tuo codice.",
      register_now: "Registrati ora",
      login: "Accedi",
      or: "oppure",
      want_delete: "Vuoi cancellare questo account?",
      want_delete_text: "Tutte le pagine create verranno eliminate e non sarà più possibile recuperarle.",
      password_confirm: "Inserisci la tua password per confermare",
      delete: "Elimina",
      cancel: "annulla",
      save: "Salva",
      open_browser: "Apri nel browser",
      add_clipboard: "Copia link",
      not_valid_data: "Dati non validi",
      not_agreed: "Devi accettare i Termini e le Condizioni & la Privacy Policy",
      terms_agree: "Accetto i Termini e le Condizioni & la Privacy Policy",
      create_page: "Crea pagina",
      create_page_details: "Inserisci solo lettere, numeri, il simbolo '-' o '_'. Non inserire spazi, punti nè caratteri speciali.",
      page_name: "Nome della pagina",
      create: "Crea",
      page_exists: "La pagina esiste già",
      want_delete_page: "Vuoi eliminare questa pagina?",
      phone_number: "Numero di telefono",
      enter_phone: "Inserisci il tuo numero di telefono",
      verification_code: "Codice",
      confirm: "Conferma",
      confirm_code: "Inserisci il codice di attivazione ricevuto via sms",
      mobile_exists: "Il numero inserito è già registrato",
      contact_us: "Contattaci!",
      need_help: "Hai bisogno di aiuto?"
    },
  },
  de: {
    translation: {
      delete_account: "Konto löschen",
      logout: "Ausloggen",
      need_account: "Willkommen am Easy Code-Entwicklungsumgebung!",
      need_account_2: "Melden Sie sich an oder erstellen Sie ein Konto, um mit dem Schreiben und Testen Ihres Codes zu beginnen.",
      register_now: "Jetzt registrieren",
      login: "Anmeldung",
      or: "oder",
      want_delete: "Möchten Sie dieses Konto löschen?",
      want_delete_text: "Alle erstellten Seiten werden gelöscht und können nicht mehr wiederhergestellt werden.",
      password_confirm: "Geben Sie zur Bestätigung Ihr Passwort ein",
      delete: "Löschen",
      cancel: "aufheben",
      save: "Speichern",
      open_browser: "Im Browser öffnen",
      add_clipboard: "Zur Zwischenablage hinzufügen",
      not_valid_data: "Ungültige Daten",
      not_agreed: "Bitte stimmen Sie den Datenschutzbestimmungen und den Allgemeinen Geschäftsbedingungen zu",
      terms_agree: "Ich stimme der Datenschutzerklärung und den Allgemeinen Geschäftsbedingungen zu",
      create_page: "Seite erstellen",
      create_page_details: "Geben Sie nur Buchstaben, Zahlen und das Symbol „-“ oder „_“ ein. Geben Sie keine Leerzeichen, Punkte oder Sonderzeichen ein.",
      page_name: "Seitenname",
      create: "Erstellen",
      page_exists: "Die Seite existiert bereits",
      want_delete_page: "Möchten Sie diese Seite löschen?",
      phone_number: "Handynummer",
      enter_phone: "Geben Sie Ihre Mobiltelefonnummer ein",
      verification_code: "Code",
      confirm: "Bestätigen",
      confirm_code: "Geben Sie den per SMS gesendeten Aktivierungscode ein",
      mobile_exists: "Die von Ihnen eingegebene Handynummer ist bereits registriert",
      contact_us: "Kontaktiere uns!",
      need_help: "Brauchst du Hilfe?"
    },
  },
  fr: {
    translation: {
      delete_account: "Supprimer le compte",
      logout: "Se déconnecter",
      need_account: "Bienvenue dans l'environnement de développement Easy Code !",
      need_account_2: "Connectez-vous ou créez un compte pour commencer à écrire et tester votre code.",
      register_now: "S'inscrire",
      login: "Connexion",
      or: "ou",
      want_delete: "Voulez-vous supprimer ce compte?",
      want_delete_text: "Toutes les pages créées seront supprimées et il ne sera plus possible de les récupérer.",
      password_confirm: "Entrez votre mot de passe pour confirmer",
      delete: "Supprimer",
      cancel: "annuler",
      save: "Enregistrer",
      open_browser: "Ouvrir dans le navigateur",
      add_clipboard: "Ajouter au presse-papiers",
      not_valid_data: "Données non valides",
      not_agreed: "Veuillez accepter la politique de confidentialité et les conditions générales",
      terms_agree: "J'accepte la politique de confidentialité et les termes et conditions",
      create_page: "Créer une page",
      create_page_details: "Entrez uniquement des lettres, des chiffres, le symbole '-' ou '_'. N'entrez pas d'espaces, de points ou de caractères spéciaux.",
      page_name: "Nom de la page",
      create: "Créer",
      page_exists: "La page existe déjà",
      want_delete_page: "Voulez-vous supprimer cette page?",
      phone_number: "Numéro de mobile",
      enter_phone: "Entrez votre numéro de mobile",
      verification_code: "Code",
      confirm: "Confirmer",
      confirm_code: "Entrez le code d'activation envoyé par sms",
      mobile_exists: "Le numéro de mobile que vous avez entré est déjà enregistré",
      contact_us: "Contactez-nous!",
      need_help: "As-tu besoin d'aide?"
    },
  },
  es: {
    translation: {
      delete_account: "Borrar cuenta",
      logout: "Cerrar sesión",
      need_account: "¡Bienvenido al entorno de desarrollo de Easy Code!",
      need_account_2: "Inicie sesión o cree una cuenta para comenzar a escribir y probar su código.",
      register_now: "Regístrate",
      login: "Acceso",
      or: "o",
      want_delete: "¿Quieres eliminar esta cuenta?",
      want_delete_text: "Todas las páginas creadas serán eliminadas y ya no será posible recuperarlas.",
      password_confirm: "Ingrese su contraseña para confirmar",
      delete: "Borrar",
      cancel: "cancelar",
      save: "Ahorrar",
      open_browser: "Abierta en el navegador",
      add_clipboard: "Copiar link",
      not_valid_data: "Datos inválidos",
      not_agreed: "Debes aceptar los Términos y Condiciones y la Política de Privacidad",
      terms_agree: "Acepto los Términos y Condiciones y la Política de Privacidad",
      create_page: "Crear página",
      create_page_details: "Ingrese solo letras, números, el símbolo '-' o '_'. No ingrese espacios, puntos o caracteres especiales.",
      page_name: "Nombre de la página",
      create: "Crear",
      page_exists: "La pagina ya existe",
      want_delete_page: "¿Quieres eliminar esta página?",
      phone_number: "Número de teléfono",
      enter_phone: "Ingrese su número telefónico",
      verification_code: "Código",
      confirm: "Confirma",
      confirm_code: "Introduzca el código de activación recibido por sms",
      mobile_exists: "El número ingresado ya está registrado",
      contact_us: "Contáctenos!",
      need_help: "¿Necesitas ayuda?"
    },
  },
  
};

const getUserLanguage = () => window.navigator.userLanguage || window.navigator.language;

i18next
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ["en", "it", "de", "fr"],
    fallbackLng: "en",
    lng: getUserLanguage(),
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;
import React, { Component } from 'react';
import loginimage from './your_idea.png';
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';
import i18n from 'i18next';
import ReactLoading from 'react-loading';

export class Login extends Component {

    constructor(props){
        super(props);
    

        this.state= { isLoading: false };
    }

  
    actionLoading = (start) => {
        this.setState({ isLoading: start });
      }

    registerNow = () => {
        this.setState({ showRegister: true, showLogin: false });
      }
    
      loginNow = () => {
        this.setState({ showRegister: false, showLogin: true });
      }

      showNoAccountScreen = (reload) => {
        this.setState({ showRegister: false, showLogin: false });
       
      }

    render() {
        return (
            <div>

{this.state.isLoading &&
        <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: 'rgba(10,10,10,0.2)',
    width: '100%',
    height: '100%'
      }}>
        <ReactLoading type="spin" color="white" height="30px" width="30px" />
        </div>
      }

        {this.state.showRegister &&
        <RegisterForm loading={this.actionLoading} goBack={this.showNoAccountScreen} />
        }
        {this.state.showLogin &&
        <LoginForm loading={this.actionLoading} goBack={this.showNoAccountScreen} />
        }
            
            {(!this.state.isLoading && this.state.info == null && !this.state.showLogin && !this.state.showRegister) &&
        <div>
            <div style={{ color: 'rgb(250,250,250)', marginTop: '30px', fontWeight: 'bold', fontSize: '15px' }}>
            {i18n.t("need_account")} 
            </div>
            <div style={{ color: 'rgb(250,250,250)', marginTop: '15px', fontWeight: 'normal', fontSize: '14px' }}>
                {i18n.t("need_account_2")} 
                </div>
            <img style={{ marginTop: '20px', width: '250px' }} src={loginimage} />
            {(!this.state.showRegister && !this.state.showLogin) &&
            <div  style={{ marginTop: '15px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '150px', borderRadius: '5px', backgroundColor: '#004999', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.registerNow}
            >  {i18n.t("register_now")}</p>
            </div>
            }
            {(!this.state.showRegister && !this.state.showLogin) &&
            <div style={{ color: 'rgb(240,240,240)', marginTop: '5px', fontWeight: 'normal', fontSize: '14px' }}>   {i18n.t("or")}</div>
            }
            {(!this.state.showRegister && !this.state.showLogin) &&
            <div  style={{ marginTop: '5px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '120px', borderRadius: '5px', backgroundColor: 'white', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#004999'}}
            onClick={this.loginNow}
            > {i18n.t("login")}</p>
            </div>
            }
            <div  style={{marginTop: '30px', color: 'rgb(200,200,200)', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             {i18n.t("need_help")}&nbsp;<p onClick={() => window.location = 'mailto:info@taikasoft.com'} style={{ cursor: 'pointer', color: 'white', fontWeight: 'normal'}}>{i18n.t("contact_us")}</p>
                </div>
             
        </div>
        }
            
            </div>
        )
    }
}

export default Login

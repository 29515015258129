import React, { Component } from 'react';
import i18n from 'i18next';
import ReactLoading from 'react-loading';
import PagesList from './PagesList';
import Page from './Page';

export class Home extends Component {

    constructor(props){
        super(props);
    

        this.state= { isLoading: false, current_page_1: null, current_page_2: null };
    }

  
    selectPage = (page) => {
        if(this.state.current_page_1 == null){
            this.setState({ current_page_1: page, current_page_2: null });
        }else{
            this.setState({ current_page_1: null, current_page_2: page });
        }       
    }

    closePage = () => {
        this.setState({ current_page_1: null, current_page_2: null });
    }

    render() {
        return (
            <div>

            <div style={{ padding: '2%', width: '96%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <div style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', backgroundColor: '#C3C3C310', width: '20%', height: '85vh' }}>
                <PagesList selectPage={this.selectPage} />
            </div>
            <div style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px',backgroundColor: '#00000050', width: '76%', height: '85vh' }}>
                {this.state.current_page_1 != null &&
                <Page closePage={this.closePage} info={this.state.current_page_1} />
                }
                 {this.state.current_page_2 != null &&
                <Page closePage={this.closePage} info={this.state.current_page_2} />
                }
            </div>
            </div>
            
            </div>
        )
    }
}

export default Home

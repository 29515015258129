import { Component } from "react";
import i18n from 'i18next';

class LoginForm extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      mobile: '',
      prefix: '39',
      isLoading: false,
      showError: '',
      code: '',
      showLogin: true
    }

  }
  componentDidMount() {
   }
 
 
  

goBack = () => {
    this.props.goBack(false);
}

confirm = () => {
  if(this.state.mobile.length < 8 || this.state.mobile.length > 12){
      this.setState({ showError: i18n.t("enter_phone") });
  }else{
      this.loginAccount();
  }
}

loginAccount = () => {
    if(this.state.isLoading){
        return;
    }
    this.props.loading(true);
    this.setState({ isLoading: true });

    
    var sessiontoken = localStorage.getItem('session_token');
    
    if(sessiontoken === null){
       window.localStorage.clear(); 
       window.location.reload();
       return;
    }

    const requestOptions = {
        method: "POST",
        headers: new Headers({
          "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        }),
        body: JSON.stringify({
          token: sessiontoken,
          mobile: this.state.mobile,
          prefix: this.state.prefix
        }),
      };
  
      var apiUrl = "https://easy-code.cloud/api/v1/user/login";
  
      fetch(apiUrl, requestOptions)
      .then((r) => r.json())
        .then((r) => {
          if(r.status == 'ko'){
            this.setState({ showError: i18n.t("not_valid_data"), isLoading: false});
            this.props.loading(false);
          }else{
            this.props.loading(false);
            this.setState({ isLoading: false, showLogin: false });
          return;
          }
        })
        .catch((e) => {
          window.location.reload();
          return;
        });
   
}

insertMobile = (e) => {
  var insert = String(e.target.value);    
 
  if(insert.indexOf(' ') > -1 ){
    insert = insert.replaceAll(" ", "");
  }
 
  if(isNaN(insert) || insert.indexOf('.') > -1 || insert.indexOf(',') > -1){
    return;
  }  
  this.setState({ mobile: insert, showError: ''});     
}

insertCode = (e) => {
  var insert = String(e.target.value);      
  this.setState({ code: insert, showError: ''});     
}

changePrefix = (e) => {
  var prefix = e.target.value;

  this.setState({ prefix: prefix });
  
}

confirmCode = () => {
  if(this.state.isLoading){
    return;
}

if(this.state.code.length != 4){
  return;
}

this.props.loading(true);
this.setState({ isLoading: true });


var sessiontoken = localStorage.getItem('session_token');

if(sessiontoken === null){
   window.localStorage.clear(); 
   window.location.reload();
   return;
}

const requestOptions = {
    method: "POST",
    headers: new Headers({
      "Accept": "application/x-www-form-urlencoded;charset=UTF-8",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    }),
    body: JSON.stringify({
      token: sessiontoken,
      code: this.state.code,
    }),
  };

  var apiUrl = "https://easy-code.cloud/api/v1/user/verify";

  fetch(apiUrl, requestOptions)
  .then((r) => r.json())
    .then((r) => {
      if(r.status == 'ko'){
        this.setState({ showError: i18n.t("not_valid_data"), isLoading: false});
        this.props.loading(false);
      }else{
        window.location.reload();
        return;
      }
    })
    .catch((e) => {
      window.location.reload();
      return;
    });
}


  render() {
    return (
      <div>
    <br />

    {this.state.showLogin && 
            <div>
            <div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
   
                <div  style={{width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', 
                backgroundColor: 'white'}}>
                   <div style={{  textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '12px'}} >
                <select onChange={this.changePrefix} value={this.state.prefix}>

                              
                <option value="39">+39</option>                   
                <option value="41">+41</option>   
                <option value="49">+49</option>   
                <option value="33">+33</option>   
                <option value="31">+31</option>   
                <option value="34">+34</option>   
                <option value="44">+44</option>    

                </select>
                </div>
                <div>
                <input 
                style={{ paddingLeft: '15px', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '180px', borderRadius: '8px', 
                backgroundColor: 'white', outlineWidth: '0', fontSize: '12px'}} type="mobile"  
                name="phone"
                autoComplete="off" 
                value={this.state.mobile}
                placeholder={i18n.t("phone_number")}
                onChange={this.insertMobile}
                />
                </div>
                </div>
                </div>
 

     

                {this.state.showError != '' &&
                <div  style={{marginTop: '30px', color: 'orange', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {this.state.showError}
                </div>
                }

                <div  style={{ marginTop: '15px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '150px', borderRadius: '5px', backgroundColor: '#004999', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirm}
            >{i18n.t("login")}</p>
            </div>
            <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'normal', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
             onClick={this.goBack}
             >{i18n.t("cancel")}</p>
             </div>

             <div  style={{marginTop: '30px', color: 'rgb(200,200,200)', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             {i18n.t("need_help")}&nbsp;<p onClick={() => window.location = 'mailto:info@taikasoft.com'} style={{ cursor: 'pointer', color: 'white', fontWeight: 'normal'}}>{i18n.t("contact_us")}</p>
                </div>
             

             </div>

        }

{!this.state.showLogin && 
<div>
<div style={{ color: 'rgb(250,250,250)', marginTop: '15px', fontWeight: 'normal', fontSize: '14px' }}>
                {i18n.t("confirm_code")} 
                </div>
                <br /><br />
<div  style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
   
   
   <div>
   <input 
   style={{textAlign: 'center', border: 'none', color: 'rgb(50,50,50)', fontWeight: 'bold', height: '40px', width: '100px', borderRadius: '8px', 
   backgroundColor: 'white', outlineWidth: '0', fontSize: '16px'}} type="mobile"  
   name="code"
   value={this.state.code}
   placeholder={i18n.t("verification_code")}
   onChange={this.insertCode}
   />
   </div>
   </div>
   {this.state.showError != '' &&
                <div  style={{marginTop: '30px', color: 'orange', fontSize: '12px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {this.state.showError}
                </div>
                }
     <div  style={{ marginTop: '15px', display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
            <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '35px', width: '150px', borderRadius: '5px', backgroundColor: '#004999', fontSize: '12px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.confirmCode}
            >{i18n.t("confirm")}</p>
            </div>
            <div  style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
             <p style={{ cursor: 'pointer',  fontWeight: 'normal', height: '25px', width: '80%', borderRadius: '8px', fontSize: '14px', 
             textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
             onClick={this.goBack}
             >{i18n.t("cancel")}</p>
             </div>
   
  
  </div>}

      </div>
    );
  }
}

export default LoginForm;
